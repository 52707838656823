import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Link from '~components/Link';
import { getNavigationPageLink } from '~utils/common-site-helper';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';
import AnalyticsTracking, { ANALYTIC_EVENT } from '~utils/analytics-tracking';

const IS_FREE_TRIAL_ENABLED = isFeatureEnabled(FEATURE_FLAGS.FREE_TRIAL);

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    zIndex: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '16px',
    alignItems: 'center',
    padding: '10px 45px 10px 25px',
    background: 'rgba(39, 39, 39, 0.90)',
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      marginTop: '-35px',
      marginBottom: '5px',
      borderRadius: '6px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: '15px 29px 18px 55px'
    }
  },
  text: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '130%',
    color: '#FFFFFF',
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      fontWeight: 400,
      lineHeight: '150%'
    }
  },
  ctaButton: {
    padding: '16px',
    textTransform: 'none',
    letterSpacing: 0,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '130%',
    textAlign: 'center',
    borderRadius: '7px',
    color: '#FFFFFF',
    backgroundColor: '#1D91FF',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: '#1D91FF',
      textDecoration: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'initial'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 15px',
      fontSize: '14px'
    }
  },
  closeBtnArea: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.down('xs')]: {
      top: 30,
      left: 4,
      right: 'unset'
    }
  },
  closeBtn: {
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  closeIcon: {
    fontSize: '18px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px'
    }
  }
}));

const LINK_ELEMENT_ID = 'subscribe-banner-cta';

const SubscribeBanner = ({ enableBanner, uid }) => {
  const classes = useStyles();
  const [hideBanner, setHideBanner] = useState(false);

  const handleBannerClose = () => {
    setHideBanner(true);
  };

  useEffect(() => {
    if (IS_FREE_TRIAL_ENABLED) {
      AnalyticsTracking.makeLinksTrackable(`#${LINK_ELEMENT_ID}`, ANALYTIC_EVENT.BANNER_CLICK, {
        Banner: 'In Video Top Banner',
        UID: uid,
        'Page Path': typeof window !== 'undefined' && window.location.pathname
      });
    }
  }, [uid]);

  if (hideBanner || !enableBanner) {
    return false;
  }

  return (
    <div className={classes.root}>
      <p className={classes.text}>
        {IS_FREE_TRIAL_ENABLED
          ? 'Start your free trial today to unlock the full library with unlimited and uninterrupted access'
          : 'Become a premium member today to unlock the full library with unlimited and uninterrupted access'}
      </p>
      <Button
        id={LINK_ELEMENT_ID}
        component={Link}
        className={classes.ctaButton}
        to={getNavigationPageLink('/subscribe', IS_FREE_TRIAL_ENABLED)}
      >
        {IS_FREE_TRIAL_ENABLED ? 'Get Started' : 'Subscribe Now'}
      </Button>
      <div className={classes.closeBtnArea}>
        <IconButton
          aria-label="close"
          onClick={handleBannerClose}
          classes={{
            root: classes.closeBtn
          }}
          disableRipple
        >
          <Close className={classes.closeIcon} />
        </IconButton>
      </div>
    </div>
  );
};

SubscribeBanner.propTypes = {
  uid: PropTypes.string,
  enableBanner: PropTypes.bool
};

SubscribeBanner.defaultProps = {
  uid: '',
  enableBanner: true
};

export default SubscribeBanner;
